import React, { useRef, useState, useEffect } from 'react'
import { Card, CardBody, Col, Row } from "reactstrap";

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";

import CatchChart from "./CatchChart";

const UserPanel = () => {
  
  const [anglerCount, setAnglerCount] = useState(0);
  const [onboardedCount, setOnboardedCount] = useState(0);
  
  const [usedTagCount, setUsedTagCount] = useState(0);
  const [unusedTagCount, setUnusedTagCount] = useState(0);
    
  const [catchCount, setCatchCount] = useState(0);
  const [recatchCount, setRecatchCount] = useState(0);
  
  const [programsCount, setProgramsCount] = useState(0);
  const [speciesCount, setSpeciesCount] = useState(0);

  
  //Table class references
  const usersRef = firebase.firestore().collection('users'); 
  const tagsRef = firebase.firestore().collection('tags'); 
  const catchesRef = firebase.firestore().collection('catches'); 
  const programsRef = firebase.firestore().collection('programs'); 
  const speciesRef = firebase.firestore().collection('species'); 
  
  //Session data references
  const userData = JSON.parse(localStorage.getItem("userData"));
  const programData = JSON.parse(localStorage.getItem("programData"));
  const programRef = firebase.firestore().collection('programs').doc(programData.id);
  
  const getAnglerCount = async () => {
   usersRef
   .onSnapshot(
        querySnapshot => {
          setAnglerCount(querySnapshot.size);
        },
        error => {
            console.log(error);
        }
    )
  };
  const getOnboardedCount = async () => {
   usersRef
   .where('onboarded', '==', true)
   .onSnapshot(
        querySnapshot => {
          setOnboardedCount(querySnapshot.size);
        },
        error => {
            console.log(error);
        }
    )
  };
    
  const getCatchCount = async () => {
      
   catchesRef
   .where('recatch', '==', false)
   .where('tag_program', '==', programRef)
   .onSnapshot(
        querySnapshot => {
          setCatchCount(querySnapshot.size);
          console.log(querySnapshot.size);
        },
        error => {
            console.log(error);
        }
    )
  };
  
  const getRecatchCount = async () => {
        
   catchesRef
   .where('recatch', '==', true)
   .where('tag_program', '==', programRef)
   .onSnapshot(
        querySnapshot => {
          setRecatchCount(querySnapshot.size);
        },
        error => {
            console.log(error);
        }
    )
  };
  
  const getUsedTagCount = async () => {
        
   tagsRef
   .where('used', '==', true)
   .where('tag_program', '==', programRef)
   .onSnapshot(
        querySnapshot => {
          setUsedTagCount(querySnapshot.size);
        },
        error => {
            console.log(error);
        }
    )
  };
  const getUnusedTagCount = async () => {
       
   tagsRef
   .where('used', '==', false)
   .where('tag_program', '==', programRef)
   .onSnapshot(
        querySnapshot => {
          setUnusedTagCount(querySnapshot.size);
        },
        error => {
            console.log(error);
        }
    )
  };
  
  const getProgramsCount = async () => {
   programsRef
   .onSnapshot(
        querySnapshot => {
          setProgramsCount(querySnapshot.size);
          console.log(querySnapshot.size);
        },
        error => {
            console.log(error);
        }
    )
  };
  
  const getSpeciesCount = async () => {
   speciesRef
   .onSnapshot(
        querySnapshot => {
          setSpeciesCount(querySnapshot.size);
          console.log(querySnapshot.size);
        },
        error => {
            console.log(error);
        }
    )
  };
  
  
  
  useEffect(() => {
    getAnglerCount();
    getOnboardedCount();
    
    getCatchCount();
    getRecatchCount();
    
    getUsedTagCount();
    getUnusedTagCount()
    
    getProgramsCount();
    getSpeciesCount();
  });

  
  return (
    <React.Fragment>
      <Row>
      
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <CatchChart
                    id="radialchart-2"
                    className="apex-charts"
                    dir="ltr"
                    amount={catchCount}
                    total={(catchCount+recatchCount)}
                  />
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Catch Count</p>
                  <h5 className="mb-3">{catchCount}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <CatchChart
                    id="radialchart-3"
                    className="apex-charts"
                    dir="ltr"
                    amount={recatchCount}
                    total={(catchCount+recatchCount)}
                  />
                </div>
        
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Recatch Count</p>
                  <h5 className="mb-3">{recatchCount}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <CatchChart
                    id="radialchart-2"
                    className="apex-charts"
                    dir="ltr"
                    amount={unusedTagCount}
                    total={(usedTagCount+unusedTagCount)}
                  />
                </div>
        
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Unused Tags</p>
                  <h5 className="mb-3">{unusedTagCount}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <CatchChart
                    id="radialchart-2"
                    className="apex-charts"
                    dir="ltr"
                    amount={usedTagCount}
                    total={(usedTagCount+unusedTagCount)}
                  />
                </div>
        
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Used Fish Tags</p>
                  <h5 className="mb-3">{usedTagCount}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        
      </Row>
      
      {/* <Row>
      
      <Col xl={3} sm={6}>
        <Card>
          <CardBody>
            <div className="d-flex text-muted">
              <div className="flex-shrink-0 me-3 align-self-center">
                <div className="avatar-sm">
                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <i className="mdi mdi-account-circle"></i>
                  </div>
                </div>
              </div>
              <div className="flex-grow-1 overflow-hidden">
                <p className="mb-1">Registered Anglers</p>
                <h5 className="mb-3">{anglerCount}</h5>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>     
      
      <Col xl={3} sm={6}>
        <Card>
          <CardBody>
            <div className="d-flex text-muted">
              <div className="flex-shrink-0 me-3 align-self-center">
                <div className="avatar-sm">
                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <i className="mdi mdi-comment-question"></i>
                  </div>
                </div>
              </div>
              <div className="flex-grow-1 overflow-hidden">
                <p className="mb-1">Onboarded Anglers</p>
                <h5 className="mb-3">{onboardedCount}</h5>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      
      <Col xl={3} sm={6}>
        <Card>
          <CardBody>
            <div className="d-flex text-muted">
              <div className="flex-shrink-0 me-3 align-self-center">
                <div className="avatar-sm">
                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <i className="mdi mdi-tag-faces"></i>
                  </div>
                </div>
              </div>
              <div className="flex-grow-1 overflow-hidden">
                <p className="mb-1">Tag Programs</p>
                <h5 className="mb-3">{programsCount}</h5>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col> 
              
      
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="mdi mdi-fish"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Fish Species</p>
                  <h5 className="mb-3">{speciesCount}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        
      </Row> */}
    </React.Fragment>
  );
};

export default UserPanel;
