import React, { useState, useEffect, useRef } from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import locationMarker from "../../assets/images/icons/location_marker.png";

const ExploreMap = (props) => {
  const [allCatches, setAllCatches] = useState([]);
  const [filteredCatches, setFilteredCatches] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [speciesOptions, setSpeciesOptions] = useState([]);
  const [filters, setFilters] = useState({ type: "all", species: "all" });
  const [sortOption, setSortOption] = useState("catch_date");
  const [locationSearch, setLocationSearch] = useState("");
  const scrollerRef = useRef(null);
  const mapRef = useRef(null);
  const autocompleteRef = useRef(null);

  // Marker icons
  const selectedMarkerIcon = {
    url: locationMarker,
    scaledSize: new props.google.maps.Size(64, 64),
  };
  const defaultMarkerIcon = {
    url: locationMarker,
    scaledSize: new props.google.maps.Size(48, 48),
  };

  useEffect(() => {
    fetchData();
    initAutocomplete();
  }, []);

  const fetchData = async () => {
    const programData = JSON.parse(localStorage.getItem("programData"));
    const programRef = firebase.firestore().collection("programs").doc(programData.id);

    // Fetch species from Firestore
    const speciesSnapshot = await firebase.firestore().collection("species").orderBy("name", "asc").get();
    const species = [];
    const speciesMapping = {};

    speciesSnapshot.forEach((doc) => {
      const data = doc.data();
      species.push({ name: data.name, id: doc.id });
      speciesMapping[doc.id] = data.name; // Map species UID to species name
    });

    setSpeciesOptions([{ id: "all", name: "All Species" }, ...species]);

    // Fetch catches from Firestore
    firebase
      .firestore()
      .collection("catches")
      .where("tag_program", "==", programRef)
      .onSnapshot((querySnapshot) => {
        const newCatches = [];
        querySnapshot.forEach((doc) => {
          const catchItem = doc.data();
          catchItem.id = doc.id;
          catchItem.name = catchItem.name || "Unknown Name";
          catchItem.location_string = catchItem.location_string || "Unknown Location";
          catchItem.image_url = catchItem.image_url || "https://via.placeholder.com/300";

          // Reference species UID to species name
          catchItem.species_name = speciesMapping[catchItem.fish_species?.id] || "Unknown Species";

          newCatches.push(catchItem);
        });
        setAllCatches(newCatches);
        setFilteredCatches(newCatches);

        // Set initial selection
        if (newCatches.length > 0) {
          setSelectedMarker(newCatches[0].id);
          centerMapOnMarker(newCatches[0].location.latitude, newCatches[0].location.longitude);
        }
      });
  };

  const applyFilters = () => {
    let filtered = [...allCatches];

    // Filter by recatch type
    if (filters.type === "new") {
      filtered = filtered.filter((item) => item.recatch === false);
    } else if (filters.type === "recatch") {
      filtered = filtered.filter((item) => item.recatch === true);
    }

    // Filter by species
    if (filters.species !== "all") {
      filtered = filtered.filter((item) => item.fish_species?.id === filters.species);
    }

    setFilteredCatches(filtered);

    if (filtered.length > 0) {
      setSelectedMarker(filtered[0].id);
      centerMapOnMarker(filtered[0].location.latitude, filtered[0].location.longitude);
    }
  };

  const applySort = () => {
    const sorted = [...filteredCatches].sort((a, b) => {
      if (sortOption === "name") return a.name.localeCompare(b.name);
      if (sortOption === "catch_date") return new Date(b.catch_date) - new Date(a.catch_date);
      return 0;
    });

    setFilteredCatches(sorted);
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  useEffect(() => {
    applySort();
  }, [sortOption]);

  const centerMapOnMarker = (latitude, longitude) => {
    const map = mapRef.current.map;
    if (map) {
      map.panTo({ lat: latitude, lng: longitude });
    }
  };

  const initAutocomplete = () => {
    const autocomplete = new props.google.maps.places.Autocomplete(autocompleteRef.current, {
      types: ["geocode"],
    });
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        centerMapOnMarker(location.lat(), location.lng());
      }
    });
  };

  const scrollHorizontally = (direction) => {
    const scroller = scrollerRef.current;
    if (scroller) {
      const scrollAmount = 300;
      scroller.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const styles = {
    filterBar: {
      position: "absolute",
      top: "80px",
      left: "270px",
      zIndex: 110,
      display: "flex",
      alignItems: "center",
      gap: "15px",
      backgroundColor: "#fff",
      padding: "10px 15px",
      borderRadius: "8px",
      boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
    },
    sortBar: {
      position: "absolute",
      top: "80px",
      left: "800px",
      zIndex: 110,
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff",
      padding: "10px 15px",
      borderRadius: "8px",
      boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
    },
    mapContainer: {
      position: "absolute",
      top: "70px",
      left: "250px",
      right: "0",
      bottom: "60px",
    },
    scrollerWrapper: {
      position: "absolute",
      bottom: "80px",
      left: "270px",
      right: "20px",
      zIndex: 110,
      backgroundColor: "#fff",
      borderRadius: "10px",
      padding: "10px",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
      display: "flex",
      alignItems: "center",
    },
    scroller: {
      display: "flex",
      overflowX: "auto",
      gap: "15px",
      scrollbarWidth: "none",
      padding: "10px",
    },
    card: {
      flex: "0 0 300px",
      height: "250px",
      backgroundColor: "#f9f9f9",
      borderRadius: "8px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      padding: "10px",
      position: "relative",
    },
    selectedCard: {
      border: "2px solid #4192C3",
    },
    cardImage: {
      width: "100%",
      height: "150px",
      objectFit: "cover",
      borderRadius: "5px",
    },
    arrowButton: {
      width: "40px",
      height: "40px",
      backgroundColor: "#4192C3",
      borderRadius: "50%",
      color: "#fff",
      fontSize: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
  };

  return (
    <React.Fragment>
      <div style={styles.filterBar}>
        <select onChange={(e) => setFilters({ ...filters, type: e.target.value })}>
          <option value="all">Filters</option>
          <option value="new">New</option>
          <option value="recatch">Recatch</option>
        </select>
        <select onChange={(e) => setFilters({ ...filters, species: e.target.value })}>
          {speciesOptions.map((species) => (
            <option key={species.id} value={species.id}>
              {species.name}
            </option>
          ))}
        </select>
        <input ref={autocompleteRef} type="text" placeholder="Search location" />
      </div>

      <div style={styles.sortBar}>
        <select onChange={(e) => setSortOption(e.target.value)}>
          <option value="nearby">Sort by Nearby</option>
          <option value="name">Sort by Name</option>
          <option value="catch_date">Sort by Date</option>
        </select>
      </div>

      <div style={styles.mapContainer}>
        <Map
          ref={mapRef}
          google={props.google}
          zoom={8}
          zoomControl
          disableDefaultUI
          zoomControlOptions={{
            position: props.google.maps.ControlPosition.TOP_RIGHT,
          }}
        >
          {filteredCatches.map((catchItem) => (
            <Marker
              key={catchItem.id}
              position={{
                lat: catchItem.location?.latitude || 0,
                lng: catchItem.location?.longitude || 0,
              }}
              icon={selectedMarker === catchItem.id ? selectedMarkerIcon : defaultMarkerIcon}
              onClick={() => {
                setSelectedMarker(catchItem.id);
                centerMapOnMarker(catchItem.location.latitude, catchItem.location.longitude);
              }}
            />
          ))}
        </Map>
      </div>

      <div style={styles.scrollerWrapper}>
        <div style={styles.arrowButton} onClick={() => scrollHorizontally("left")}>
          &lt;
        </div>
        <div ref={scrollerRef} style={styles.scroller}>
          {filteredCatches.map((catchItem) => (
            <div
              key={catchItem.id}
              style={{
                ...styles.card,
                ...(selectedMarker === catchItem.id ? styles.selectedCard : {}),
              }}
              onClick={() => {
                setSelectedMarker(catchItem.id);
                centerMapOnMarker(catchItem.location.latitude, catchItem.location.longitude);
              }}
            >
              <img src={catchItem.image_url} alt="catch" style={styles.cardImage} />
              <h4>{catchItem.name}</h4>
              <p>{catchItem.species_name}</p>
              <p>{catchItem.location_string}</p>
            </div>
          ))}
        </div>
        <div style={styles.arrowButton} onClick={() => scrollHorizontally("right")}>
          &gt;
        </div>
      </div>
    </React.Fragment>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCcnZ45Qluty82SrlZkkjbFZFKFflYbiZQ",
})(ExploreMap);