import React, { useRef, useState, useEffect } from 'react'
import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import List from 'list.js';
import { ThreeCircles } from 'react-loader-spinner'
import Flatpickr from "react-flatpickr";

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp, arrayUnion, arrayRemove} from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, uploadBytes, getStorage, ref } from "firebase/storage";

const ProgramUsers = () => {
    
    const storage = getStorage();
    
    //Session data references
    const userObject = JSON.parse(localStorage.getItem("userData"));
    const programObject = JSON.parse(localStorage.getItem("programData"));
    const programRef = firebase.firestore().collection('programs').doc(programObject.id);
    
    const [initialLoad, setInitialLoad] = useState(true);
    const [allUsersList, setAllUsersList] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    
    //Action buttons
    const [isCreating, setIsCreating] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateUserId, setUpdateUserId] = useState('');
    const [updateUserIndex, setUpdateUserIndex] = useState(null);
    const [deleteUserId, setDeleteUserId] = useState('');
    const [deleteUserIndex, setDeleteUserIndex] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [enableUserId, setEnableUserId] = useState('');
    const [enableUserIndex, setEnableUserIndex] = useState(null);
    const [isActivating, setIsActivating] = useState(false);
    const [updatedImage, setUpdatedImage] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);
        
    document.title = "Program Users | Fish-Trak - Partner's Dashboard";

    const [deleteModal, setDeleteModal] = useState(false);
    function toggleDeleteModal(userId, userIndex) {
        
        if(deleteModal) {
            setDeleteUserId('');
            setDeleteUserIndex(null);
        }
        else {
            setDeleteUserId(userId);
            setDeleteUserIndex(userIndex);
        }
        
        setDeleteModal(!deleteModal);
        
    }
    
    const disableUser = async () => {
        
        setIsDeleting(true);
                
        //Delete onboarding from database
        const userRef = firebase.firestore().collection('users').doc(allUsersList[deleteUserIndex].id);

            userRef
                .update({
                    active: false
                  })
                .then((userResponse) => {
                  fetchAllUsers();
                  alert('The user account has been disabled successfully!');
                  setDeleteUserId('');
                  setDeleteUserIndex(null);
                })
                .catch((error) => {
                  setIsDeleting(false);
                  alert('Could not disable user. Please try again.');
                });  
    };
    
    const [activateModal, setActivateModal] = useState(false);
    function toggleActivateModal(userId, userIndex) {
        
        if(activateModal) {
            setEnableUserId('');
            setEnableUserIndex(null);
        }
        else {
            setEnableUserId(userId);
            setEnableUserIndex(userIndex);
        }
        
        setActivateModal(!activateModal);
        
    }
    
    const activateUser = async () => {
        
        setIsActivating(true);
                
        //Delete onboarding from database
        const userRef = firebase.firestore().collection('users').doc(allUsersList[enableUserIndex].id);
    
            userRef
                .update({
                    active: true
                  })
                .then((userResponse) => {
                  fetchAllUsers();
                  alert('The user account has been activated successfully!');
                  setEnableUserId('');
                  setEnableUserIndex(null);
                })
                .catch((error) => {
                  setIsActivating(false);
                  alert('Could not activate user. Please try again.');
                });  
    };

    
    const [createModal, setCreateModal] = useState(false);
    function toggleCreateModal() {
        setCreateModal(!createModal);
    }
    
    const submitCreateForm = async (event) => {
    
        setIsUploading(true);
        event.preventDefault();
        
        setIsCreating(true);
        
        if(uploadedImage) {
            uploadImage(event);
        }
        else {
            createUser(event);
        }
    
    }
    
    const uploadImage = async (event) => {
        
      event.preventDefault();
      const emailAddress = event.target.email_address.value.replace(/[^a-zA-Z0-9+-_~!#$%&'./=^|{}"]/g, '')
      const timeStamp = Date.now();
                    
      const imageFileName = '/user-avatars/' + emailAddress + '-' + timeStamp;
      
      const storageRef = ref(storage, imageFileName);
    
      const uploadTask = uploadBytesResumable(storageRef, uploadedImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("upload is" + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload paused");
              break;
            case "running":
              console.log("Upload running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          setIsUploading(false);
            alert('Could not upload user avatar. Please try again.');
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
            createUser(event, downloadedURL);
          });
        }
      );
    };
    
    const generateRandomPassword = () => {
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
      const length = 12;
      return Array.from({ length }, () => charset[Math.floor(Math.random() * charset.length)]).join("");
    };
    
    const createUser = async (event, downloadedURL) => {
        
        setIsUploading(false);
        
        const emailAddress = event.target.email_address.value.replace(/[^a-zA-Z0-9+-_~!#$%&@'./=^|{}"]/g, '');
        const userPassword = generateRandomPassword();
        const locationGeo = new firebase.firestore.GeoPoint(parseFloat(event.target.home_port_lat.value.replace(/[^0-9.-]/g, '')), parseFloat(event.target.home_port_lng.value.replace(/[^0-9.-]/g, '')));
        
        const avatarPlaceholderImage = 'https://firebasestorage.googleapis.com/v0/b/fish-trak-app.appspot.com/o/program-logos%2Ffish-trak-program-logo.png?alt=media&token=8ef8bf14-1c21-4dd1-8ffc-6f860f41dfa6';
        
        firebase
          .auth()
          .createUserWithEmailAndPassword(emailAddress, userPassword)
          .then((response) => {
              response.user.sendEmailVerification();
              const uid = response.user.uid;
                
              //Create object of user data
              const userData = {       
                id: uid,   
                account_type: 1,
                active: true,
                approved: true,
                verified: false,
                onboarded: false,
                catch_count: 0,
                created_at: serverTimestamp(),
                recatch_count: 0,
                signup_location: locationGeo,
                species_count: 0,
                text_messages: true,
                updated_at: serverTimestamp(),
                privacy_mode: false,
                home_port_geo: locationGeo,
                email_address: emailAddress,
                email_updates: true,
                text_messages: true,
                recatch_alerts: true,
                notifications: true,
                admin_user: false,
                partner_user: true,
                program_access: programRef,
                
                first_name: event.target.first_name.value.replace(/[^a-zA-Z&-,.' ]/g, ''),
                last_name: event.target.last_name.value.replace(/[^a-zA-Z&-,.' ]/g, ''),
                mobile_number: event.target.mobile_number.value.replace(/[^0-9+]/g, ''),
                home_port: event.target.home_port.value.replace(/[^a-zA-Z&()-+',./' ]/g, ''),
                country_code: event.target.user_country.value.replace(/[^a-zA-Z]/g, ''),
                metric_system: JSON.parse(event.target.units.value),
                image_url: !!downloadedURL ? downloadedURL : avatarPlaceholderImage
              }
              
              const usersRef = firebase.firestore().collection('users');
              usersRef
                  .doc(uid)
                  .set(userData)
                  .then(() => {
                      
                      firebase
                        .auth()
                        .sendPasswordResetEmail(emailAddress)
                        .then(() => {
                            alert('The user has been created successfully!');
                            fetchAllUsers(); 
                        })
                      
                  })
                  .catch((error) => {
                    setIsCreating(false);
                      alert('Could not create account. Please try again.');
                  });
        })      
        .catch((error) => {
            setIsCreating(false);
            
            if (error.code === 'auth/email-already-in-use') {
              alert('That email address is already registered. Please use a different email.');
            }
            else {
              alert('Could not create account. Please try again.');
            }            
              
        });      
        
    };
    
    
    const [editModal, setEditModal] = useState(false);
    function toggleEditModal(tagId, tagIndex) {
        
        if(editModal) {
            setUpdateUserId('');
            setUpdateUserIndex(null);
        }
        else {
            setUpdateUserId(tagId);
            setUpdateUserIndex(tagIndex);
        }
        
        setEditModal(!editModal);
    }
    
    const submitUpdateForm = async (event) => {
    
        setIsUploading(true);
        event.preventDefault();
        
        setIsUpdating(true);
        
        if(updatedImage) {
            updateImage(event);
        }
        else {
            updateUser(event);
        }
    
    }
    
    const updateImage = async (event) => {
        
        event.preventDefault();
                    
      const imageFileName = '/user-avatars/' + allUsersList[updateUserIndex].id;
      
      const storageRef = ref(storage, imageFileName);
    
      const uploadTask = uploadBytesResumable(storageRef, updatedImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("upload is" + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload paused");
              break;
            case "running":
              console.log("Upload running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          setIsUpdating(false);
            alert('Could not update user avatar. Please try again.');
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
            updateUser(event, downloadedURL);
          });
        }
      );
    };
    
    const updateUser = async (event, downloadedURL) => {
                
      //Create object of program data
        const userData = {
          updated_at: serverTimestamp(),
        };
        
        //User information      
        if(event.target.first_name.value != allUsersList[updateUserIndex].first_name) {
            userData['first_name'] = event.target.first_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
        }  
        if((allUsersList[updateUserIndex].last_name) && (event.target.last_name.value != allUsersList[updateUserIndex].last_name)) {
            userData['last_name'] = event.target.last_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
        } 
        else if((!allUsersList[updateUserIndex].last_name) || (allUsersList[updateUserIndex].last_name == '') || (allUsersList[updateUserIndex].last_name == null)) {
            userData['last_name'] = event.target.last_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
        }
        //Contact details
        if(event.target.email_address.value != allUsersList[updateUserIndex].email_address) {
            userData['email_address'] = event.target.email_address.value.replace(/[^a-zA-Z0-9+-_~!#$%&'./=^|{}"]/g, '');
        }  
        if(event.target.mobile_number.value != allUsersList[updateUserIndex].mobile_number) {
            userData['mobile_number'] = event.target.mobile_number.value.replace(/[^0-9+]/g, '');
        }  
        //Location data
        if(event.target.home_port.value != allUsersList[updateUserIndex].home_port) {
            userData['home_port'] = event.target.home_port.value.replace(/[^a-zA-Z&()-+',./' ]/g, '');
        } 
        if((event.target.home_port_lat.value != allUsersList[updateUserIndex].home_port_geo.latitude) || (event.target.home_port_lng.value != allUsersList[updateUserIndex].home_port_geo.longitude)) {
            userData['location'] = new firebase.firestore.GeoPoint(parseFloat(event.target.home_port_lat.value.replace(/[^0-9.-]/g, '')), parseFloat(event.target.home_port_lng.value.replace(/[^0-9.-]/g, '')))
        }
        if(event.target.user_country.value != allUsersList[updateUserIndex].country_code) {
            userData['country_code'] = event.target.user_country.value.replace(/[^a-zA-Z]/g, '');
        }
       
        //Metric counts 
        if(event.target.catch_count.value != allUsersList[updateUserIndex].catch_count) {
            userData['catch_count'] = !!event.target.catch_count.value ? event.target.catch_count.value.replace(/[^0-9]/g, '') : 0;
        } 
        if(event.target.recatch_count.value != allUsersList[updateUserIndex].recatch_count) {
            userData['recatch_count'] = !!event.target.recatch_count.value ? event.target.recatch_count.value.replace(/[^0-9]/g, '') : 0;
        } 
        if(event.target.species_count.value != allUsersList[updateUserIndex].species_count) {
            userData['species_count'] = !!event.target.species_count.value ? event.target.species_count.value.replace(/[^0-9]/g, '') : 0;
        }         
        //Account Status
        if(event.target.units.value != allUsersList[updateUserIndex].metric_system) {
            userData['metric_system'] = JSON.parse(event.target.units.value);
        }
        //Account Status
        if(event.target.active.value != allUsersList[updateUserIndex].active) {
            userData['active'] = event.target.active.checked;
        }
        if(event.target.approved.value != allUsersList[updateUserIndex].approved) {
            userData['approved'] = event.target.approved.checked;
        }
        
        //Notifications
        if(event.target.email_updates.value != allUsersList[updateUserIndex].email_updates) {
            userData['email_updates'] = event.target.email_updates.checked;
        }
        if(event.target.text_messages.value != allUsersList[updateUserIndex].text_messages) {
            userData['text_messages'] = event.target.text_messages.checked;
        }
        if(event.target.recatch_alerts.value != allUsersList[updateUserIndex].recatch_alerts) {
            userData['recatch_alerts'] = event.target.recatch_alerts.checked;
        }
        if(event.target.push_notifications.value != allUsersList[updateUserIndex].notifications) {
            userData['notifications'] = event.target.push_notifications.checked;
        }
  
        //Persmissions
        if(event.target.partner_access.checked != allUsersList[updateUserIndex].partner_user) {
            userData['partner_user'] = event.target.partner_access.checked;
        }
        if(event.target.privacy_mode.checked != allUsersList[updateUserIndex].privacy_mode) {
              userData['privacy_mode'] = event.target.privacy_mode.checked;
        }
        
        if(allUsersList[updateUserIndex].partner_user && !event.target.partner_access.checked) {
            userData['program_access'] = null;
        }
        
        if(downloadedURL) {
            userData['image_url'] = downloadedURL;
        }
                
        //Update user in firestore
        const userRef = firebase.firestore().collection('users').doc(allUsersList[updateUserIndex].id);
        userRef
            .update(userData)
            .then((userResponse) => {
                
                if(!event.target.partner_access.checked && allUsersList[updateUserIndex].partner_user) {
                  const programRef = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
                  const programManagers = {
                    program_managers: arrayRemove(userRef)
                  }
                  programRef.update(programManagers).then((response) => {
                    alert('The user has been updated successfully!');
                    fetchAllUsers(); 
                  });
                }
                else {
                  alert('The user has been updated successfully!');
                  fetchAllUsers(); 
                }
              
              
            })
            .catch((error) => {
              setIsUpdating(false);
              alert('Could not update user entry. Please try again.');
            });  
    };
    
    const createList = () => {
                   
           const usersListOptions = {
               valueNames: ['image_url', 'name', 'email', 'port', 'created', 'updated', 'status'],
               pagination: true,
               indexAsync: true,
               searchColumns: ['name', 'email', 'port'],
               page: 10
           };    
         
         new List('users-list', usersListOptions);
    };
    
    const fetchAllUsers =  () => {
          
     firebase
     .firestore()
     .collection('users')
     .orderBy('created_at', 'desc')
     .where('partner_user', '==', true)
     .where('program_access', '==', programRef)
     .onSnapshot(
          querySnapshot => {
              const newUsersList = []
              querySnapshot.forEach(doc => {
                  const userItem = doc.data();
                  userItem.id = doc.id;
                  
                  userItem.name = userItem.first_name + ' ' + userItem.last_name;
                  userItem.email = userItem.email_address;
                  userItem.port = userItem.home_port;
                  const createdAt = userItem && userItem.created_at && userItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  userItem.created = createdAt;
                  const updatedAt = userItem && userItem.updated_at && userItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  userItem.updated = updatedAt;
                  userItem.count = userItem.catch_count;
                  userItem.status = userItem.active;
                  userItem.link = 'user/details/' + userItem.id;      
                  
                  userItem.mailtoLink = 'mailto:' + userItem.email_address + '?subject=Fish-Trak Admin';
                  
                  newUsersList.push(userItem);
              });
              setAllUsersList(newUsersList);
              setEditModal(false);
              setCreateModal(false);
              setIsUploading(false);
              setIsUpdating(false);
              setDeleteModal(false);
              setIsDeleting(false);
              setFetchedData(true);
              setActivateModal(false);
              setIsActivating(false);
              setIsCreating(false);
              
          },
          error => {
              console.log(error);
          }
      )
    };

    useEffect(() => {

        if(initialLoad) {
           setInitialLoad(false);
           fetchAllUsers();
         }
         
         if(fetchedData && (allUsersList.length > 0)) {
              setFetchedData(false);
              createList();
            }  
                 
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <CardTitle>My Program</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Access Users</h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="users-list">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" id="create-btn" onClick={() => toggleCreateModal()} data-bs-toggle="modal" data-bs-target="#createModal"><i className="ri-add-line align-bottom me-1"></i> Add New User</Button>
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="search" className="fuzzy-search form-control" placeholder="Search Users..." />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                        {!isUpdating && !isDeleting && !isActivating && !isCreating &&
                                            <table className="table align-middle table-nowrap" id="users-table">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th data-sort="image_url">Image</th>
                                                        <th className="sort" data-sort="name">User Name</th>
                                                        <th className="sort" data-sort="email">Email Address</th>
                                                        <th className="sort" data-sort="port">User Location</th>
                                                        <th className="sort" data-sort="created" data-default-order='desc'>Created Date</th>
                                                        <th className="sort" data-sort="updated" data-default-order='desc'>Last Active</th>
                                                        <th className="sort" data-sort="status">Status</th>
                                                        <th className="sort" data-sort="action">User Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form">
                                                
                                                {(allUsersList.length > 0) && allUsersList.map((user, index) => ( 
                                                    <tr key={index}>
                                                        <th scope="row">
                                                            {user.partner_user &&
                                                                <a title="View Angler" className="btn btn-sm btn-success edit-item-btn" href={user.link} style={{ backgroundColor: "purple" }}><i className="mdi mdi-eye-outline"></i></a>
                                                            }
                                                            {!user.partner_user &&
                                                                <a title="View Angler" className="btn btn-sm btn-success edit-item-btn" href={user.link}><i className="mdi mdi-eye-outline"></i></a>
                                                            }
                                                        </th>
                                                        <td className="image_url">
                                                            {user.image_url &&<img className="avatar-sm rounded-circle" alt={user.name} src={user.image_url} />}
                                                        </td>
                                                        <td className="name">{user.name}</td>
                                                        <td className="email"><a href={user.mailtoLink} class="email-link">{user.email}</a></td>
                                                        <td className="port">{user.port}</td>
                                                        <td className="created">{user.created}</td>
                                                        <td className="updated">{user.updated}</td>
                                                        <td className="status">
                                                            {user.active &&
                                                                <span className="badge badge-soft-success text-uppercase">Active</span>
                                                            }
                                                            {!user.active &&
                                                                <span className="badge badge-soft-danger text-uppercase">Disabled</span>
                                                            }
                                                        </td>
  
                                                        <td className="action">
                                                            <div className="d-flex gap-2">
                                                            
                                                                <div className="edit">
                                                                        <a className="btn btn-sm btn-success edit-item-btn" href={user.mailtoLink} title="Email Angler"><i className="mdi mdi-email-send"></i></a>
                                                                </div>
                                                            
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-warning edit-item-btn"
                                                                        onClick={() => toggleEditModal(user.id, index)}
                                                                        data-bs-toggle="modal" data-bs-target="#showModal" title="Edit User"><i className="mdi mdi-square-edit-outline"></i></button>
                                                                </div>
                                                                {user.active &&
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" title="Disable User" data-bs-target="#deleteRecordModal" onClick={() => toggleDeleteModal(user.id, index)}><i className="mdi mdi-account-off-outline"></i></button>
                                                                </div>
                                                                }
                                                                {!user.active &&
                                                                    <div className="edit">
                                                                        <button className="btn btn-sm btn-success edit-item-btn"  style={{ backgroundColor: "green" }} title="Activate User" onClick={() => toggleActivateModal(user.id, index)} data-bs-toggle="modal" data-bs-target="#activateModal"><i className="mdi mdi-account-check-outline"></i></button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </table>}
                                            
                                            {allUsersList.length == 0 &&
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Users Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any users.</p>
                                                </div>
                                            </div>
                                            }
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    

                    
                </Container>
            </div>
            
            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={() => { toggleEditModal(); }} centered >
                <ModalHeader className="bg-light p-3" id="editModal" toggle={() => { toggleEditModal(); }}> Edit User </ModalHeader>
                
                {!isUpdating && allUsersList[updateUserIndex] &&
                    <form className="tablelist-form" onSubmit={submitUpdateForm}>
                        <ModalBody>
                            <div className="mb-3"><tr>
                                <td>
                                <label htmlFor="firstname-field" className="form-label">First Name*</label>
                                <input type="text" id="firstname-field" name="first_name" className="form-control" placeholder="Richard" required defaultValue={allUsersList[updateUserIndex].first_name}/>
                                </td>
                                <td>
                                <label htmlFor="lastname-field" className="form-label">Last Name*</label>
                                <input type="text" id="lastname-field" className="form-control" name="last_name" placeholder="Ryan" required defaultValue={allUsersList[updateUserIndex].last_name}/>
                                </td>
                            </tr></div>
                            <div className="mb-3">
                                <label htmlFor="email-field" className="form-label">Email Address*</label>
                                <input type="email" id="email-field" name="email_address" className="form-control" placeholder="rich@fish-trak.com" required defaultValue={allUsersList[updateUserIndex].email_address}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="mobile-field" className="form-label">Mobile Number*</label>
                                <input type="tel" id="mobile-field" className="form-control" placeholder="+14151234567" name="mobile_number" required defaultValue={allUsersList[updateUserIndex].mobile_number}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="image-field" className="form-label">User Avatar*</label>
                                <tr>
                                <td><img className="avatar-sm rounded-circle" alt={allUsersList[updateUserIndex].name} src={allUsersList[updateUserIndex].image_url}/></td>
                                <td><input type="file" name="image_link" id="image-field" className="form-control" onChange={(e) => { setUpdatedImage(e.target.files[0]) }} style={{marginLeft: 20, width: "100%"}}/></td>
                                </tr>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="homeport-field" className="form-label">Home Port Location*</label>
                                <input type="text" id="homeport-field" className="form-control" name="home_port" placeholder="Toms River" required defaultValue={allUsersList[updateUserIndex].home_port}/>
                            </div>
                            <div className="mb-3"><tr>
                                <td>
                                <label htmlFor="latitude-field" className="form-label">Home Port Latitude*</label>
                                <input type="number" id="latitude-field" className="form-control" name="home_port_lat" placeholder="Enter Latitude*" step="any" required defaultValue={allUsersList[updateUserIndex].home_port_geo.latitude} />
                                </td>
                                <td>
                                <label htmlFor="longitude-field" className="form-label">Home Port Longitude*</label>
                                <input type="number" id="longitude-field" className="form-control" name="home_port_lng" placeholder="Enter Longitude*" step="any" required defaultValue={allUsersList[updateUserIndex].home_port_geo.longitude} />
                                </td>
                            </tr>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="country-field" className="form-label">User Country*</label>
                                <select className="form-control" data-trigger id="country-field" required name="user_country" defaultValue={allUsersList[updateUserIndex].country_code}>
                                    <option value="">Select User Country</option>
                                    <option value="USA">United States</option>
                                    <option value="CAN">Canada</option>
                                </select>
                            </div>
                            
                            <div className="mb-3">
                                <label htmlFor="units-radio" className="form-label">Units System*</label>
                                <tr>
                                <td>
                                    <input type="radio" id="metric" name="units" value="true" defaultChecked={!allUsersList[updateUserIndex].metric_system} />
                                    <label style={{marginRight: 15, marginLeft: 5}} htmlFor="metric" className="form-label">Metric</label>
                                </td>
                                <td>
                                    <input type="radio" id="imperial" name="units" value="false" defaultChecked={allUsersList[updateUserIndex].metric_system} />
                                    <label style={{marginLeft: 5}} htmlFor="imperial" className="form-label">Imperial</label>
                                </td>
                            </tr></div>
                            
                            <div className="mb-3">
                                <label htmlFor="notifications-checkbox" className="form-label">Account Status*</label>
                                <tr>
                                    <td>
                                        <input type="checkbox" id="active" name="active" value="true" defaultChecked={allUsersList[updateUserIndex].active}/>
                                        <label style={{marginRight: 15, marginLeft: 5}} htmlFor="active"> Activated</label>
                                    </td>
                                    <td>
                                        <input type="checkbox" id="approved" name="approved" value="true" defaultChecked={allUsersList[updateUserIndex].approved}/>
                                        <label style={{marginLeft: 5}} htmlFor="approved"> Approved</label>
                                    </td>
                                </tr>
                            </div>
                            
                            <div className="mb-3">
                                <label htmlFor="notifications-checkboxes" className="form-label">Notifications*</label>
                                <tr>
                                    <td>
                                        <input type="checkbox" id="emails" name="email_updates" value="true" defaultChecked={allUsersList[updateUserIndex].email_updates}/>
                                        <label style={{marginRight: 15, marginLeft: 5}} htmlFor="emails"> Email Updates</label>
                                    </td>
                                    <td>
                                        <input type="checkbox" id="texts" name="text_messages" value="true" defaultChecked={allUsersList[updateUserIndex].text_messages}/>
                                        <label style={{marginLeft: 5}} htmlFor="texts"> Text Messages</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" id="alerts" name="recatch_alerts" value="true" defaultChecked={allUsersList[updateUserIndex].recatch_alerts}/>
                                        <label style={{marginRight: 15, marginLeft: 5}} htmlFor="alerts"> Recatch Alerts</label>
                                    </td>
                                    <td>
                                        <input type="checkbox" id="pushes" name="push_notifications" value="true" defaultChecked={allUsersList[updateUserIndex].notifications}/>
                                        <label style={{marginLeft: 5}} htmlFor="pushes"> Push Notifications</label>
                                    </td>
                                </tr>
                            </div>
                            
                            <div className="mb-3">
                                <label htmlFor="permissions-checkboxes" className="form-label">Persmissions*</label>
                                <tr>
                                    <td>
                                        <input type="checkbox" id="partner" name="partner_access" value="true" defaultChecked={allUsersList[updateUserIndex].partner_user}/>
                                        <label style={{marginRight: 15, marginLeft: 5}} htmlFor="partner"> Partner Access</label>
                                    </td>
                                    <td>
                                        <input type="checkbox" id="privacy_mode" name="privacy_mode" value="true" defaultChecked={allUsersList[updateUserIndex].privacy_mode}/>
                                        <label style={{marginLeft: 5}} htmlFor="privacy_mode"> Privacy Mode</label>
                                    </td>
                                </tr>
                            </div>
                            
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={() => toggleEditModal()}>Cancel</button>
                                <button type="submit" className="btn btn-success" id="add-btn">Update</button>
                            </div>
                        </ModalFooter>
                    </form>
                    }
                    
                    <ModalBody>
                        <ThreeCircles
                          visible={isUpdating}
                          height="100"
                          width="100"
                          color="#4192C3"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{style:"margin: 0px auto"}}
                          wrapperClass="justify-content-center"
                          />
                    </ModalBody>
                </Modal>

            {/* Disable Modal */}
            <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(); }} className="modal zoomIn" id="deleteRecordModal" centered >
            
                {!isDeleting &&
                <ModalBody>
                    <div className="mt-2 text-center">
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Disable User's Account</h4>
                            <p className="text-muted mx-4 mb-0">Are you sure you want to disable this user?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteModal()}>Cancel</button>
                        <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={disableUser}>Disable</button>
                    </div>
                </ModalBody>
                }
                    
                    
                    <ModalBody>
                        <ThreeCircles
                          visible={isDeleting}
                          height="100"
                          width="100"
                          color="#ff3d60"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{style:"margin: 0px auto"}}
                          wrapperClass="justify-content-center"
                          />
                    </ModalBody>
            </Modal>
            
            {/* Activate Modal */}
            <Modal isOpen={activateModal} toggle={() => { toggleActivateModal(); }} className="modal zoomIn" id="activateModal" centered >
            
                {!isActivating &&
                <ModalBody>
                    <div className="mt-2 text-center">
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Activate User's Account</h4>
                            <p className="text-muted mx-4 mb-0">Are you sure you want to activate this user?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => toggleActivateModal()}>Cancel</button>
                        <button type="button" className="btn w-sm btn-success" id="delete-record" onClick={activateUser}>Activate</button>
                    </div>
                </ModalBody>
                }
                    
                    
                    <ModalBody>
                        <ThreeCircles
                          visible={isActivating}
                          height="100"
                          width="100"
                          color="#4192C3"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{style:"margin: 0px auto"}}
                          wrapperClass="justify-content-center"
                          />
                    </ModalBody>
            </Modal>
            
            
            
            {/* Create Modal */}
            <Modal isOpen={createModal} toggle={() => { toggleCreateModal(); }} centered >
                <ModalHeader className="bg-light p-3" id="createModal" toggle={() => { toggleCreateModal(); }}> Create User </ModalHeader>
                
                {!isCreating &&
                <form className="tablelist-form" onSubmit={submitCreateForm}>
                    <ModalBody>
                        <div className="mb-3"><tr>
                            <td>
                            <label htmlFor="firstname-field" className="form-label">First Name*</label>
                            <input type="text" id="firstname-field" name="first_name" className="form-control" placeholder="Richard" required/>
                            </td>
                            <td>
                            <label htmlFor="lastname-field" className="form-label">Last Name*</label>
                            <input type="text" id="lastname-field" className="form-control" name="last_name" placeholder="Ryan" required/>
                            </td>
                        </tr></div>
                        <div className="mb-3">
                            <label htmlFor="email-field" className="form-label">Email Address*</label>
                            <input type="email" id="email-field" name="email_address" className="form-control" placeholder="rich@fish-trak.com" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="mobile-field" className="form-label">Mobile Number*</label>
                            <input type="tel" id="mobile-field" className="form-control" placeholder="+14151234567" name="mobile_number" required/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="image-field" className="form-label">User Avatar*</label>
                            <tr>
                            <td><input type="file" name="image_link" id="image-field" className="form-control" onChange={(e) => { setUploadedImage(e.target.files[0]) }} style={{marginLeft: 20, width: "100%"}}/></td>
                            </tr>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="homeport-field" className="form-label">Home Port Location*</label>
                            <input type="text" id="homeport-field" className="form-control" name="home_port" placeholder="Toms River" required />
                        </div>
                        <div className="mb-3"><tr>
                            <td>
                            <label htmlFor="latitude-field" className="form-label">Home Port Latitude*</label>
                            <input type="number" step="any" id="latitude-field" className="form-control" name="home_port_lat" placeholder="Enter Latitude*" required />
                            </td>
                            <td>
                            <label htmlFor="longitude-field" className="form-label">Home Port Longitude*</label>
                            <input type="number" step="any" id="longitude-field" className="form-control" name="home_port_lng" placeholder="Enter Longitude*" required />
                            </td>
                        </tr>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="country-field" className="form-label">User Country*</label>
                            <select className="form-control" data-trigger id="country-field" required name="user_country">
                                <option value="">Select User Country</option>
                                <option value="USA">United States</option>
                                <option value="CAN">Canada</option>
                            </select>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="units-radio" className="form-label">Units System*</label>
                            <tr>
                            <td>
                                <input type="radio" id="metric" name="units" value="true" defaultChecked={true} />
                                <label style={{marginRight: 15, marginLeft: 5}} htmlFor="metric" className="form-label">Metric</label>
                            </td>
                            <td>
                                <input type="radio" id="imperial" name="units" value="false" defaultChecked={false} />
                                <label style={{marginLeft: 5}} htmlFor="imperial" className="form-label">Imperial</label>
                            </td>
                        </tr></div>
                        
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => toggleCreateModal()}>Cancel</button>
                            <button type="submit" className="btn btn-success" id="add-btn">Create</button>
                        </div>
                    </ModalFooter>
                </form>
                }
                
                <ModalBody>
                    <ThreeCircles
                      visible={isCreating}
                      height="100"
                      width="100"
                      color="#4192C3"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{style:"margin: 0px auto"}}
                      wrapperClass="justify-content-center"
                      />
                </ModalBody>
            </Modal>
            
        </React.Fragment>
    );
};

export default ProgramUsers;
