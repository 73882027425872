
import React, { useState, useEffect } from "react";

import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader, Alert, Label, Input, FormFeedback, Form } from 'reactstrap';

import { Link, useParams, useNavigate } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner'

//Import Firebase Functionality
import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp} from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, uploadBytes, getStorage, ref } from "firebase/storage";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

const ProgramSettings = () => {
  
  document.title = "Program Profile | Fish-Trak - Partner's Dashboard";
  
  //Firebase functionality
  const storage = getStorage();
  const navigate = useNavigate();
  
  //Session booleans
  const [initialLoad, setInitialLoad] = useState(true);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [fetchedData, setFetchedData] = useState(false);
    
  //Session data references
  const userObject = JSON.parse(localStorage.getItem("userData"));
  const programObject = JSON.parse(localStorage.getItem("programData"));
  const programRef = firebase.firestore().collection('programs').doc(programObject.id);
  
  //Action references
  const [isUploading, setIsUploading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatedImage, setUpdatedImage] = useState(null);

  const submitUpdateForm = async (event) => {
  
      event.preventDefault();
      
      setIsUpdating(true);
      
      updateSettings(event);
  
  }

  const updateSettings = async (event) => {
      
    //Create object of program data
      const programData = {
        updated_at: serverTimestamp(),
      };
      
      //Catch Alerts
      if(event.target.catch_alerts.value != programData.catch_alerts) {
          programData['catch_alerts'] = JSON.parse(event.target.catch_alerts.value);
      }
      
      //Recatch Alerts
      if(event.target.recatch_alerts.value != programData.recatch_alerts) {
          programData['recatch_alerts'] = JSON.parse(event.target.recatch_alerts.value);
      }
      
      //Catch Alerts
      if(event.target.instructions.value != programData.instructions) {
          programData['instructions'] = JSON.parse(event.target.instructions.value);
      }
      
      //Catch Alerts
      if(event.target.questionnaire.value != programData.questionnaire) {
          programData['questionnaire'] = JSON.parse(event.target.questionnaire.value);
      }
              
      //Update program data
      programRef
          .update(programData)
          .then((programResponse) => {
            setFetchingDetails(true);
            getProgramDetails(); 
          })
          .catch((error) => {
            setIsUpdating(false);
            alert('Could not update program entry. Please try again.');
          });  
  };
  
  //Get user details
  const getProgramDetails = async () => {
    programRef
    .get()
    .then((programDoc) => {
      const programData = programDoc.data();
      programData.id = programDoc.id;   
      localStorage.setItem("programData", JSON.stringify(programData));         
      setFetchingDetails(false);
      setIsUploading(false);
      setIsUpdating(false);
      setFetchedData(true);
      
    })
    .catch((error) => {
      setFetchingDetails(false);
      alert('Could not get program details. Please try again.');
    });  
  };

  useEffect(() => {
  });

  return (
    <React.Fragment>
        <div className="page-content">
          <Container fluid>
 
            <Card>
              
              <CardHeader>
                  <h4 className="card-title mb-0">Program Settings</h4>
              </CardHeader>
 
              <CardBody>
                {!isUpdating && programObject &&
                <Form className="form-horizontal" onSubmit={submitUpdateForm}>
                
                  <div className="mb-3">
                      <label htmlFor="units-radio" className="form-label">Catch Alert Emails*</label>
                      <tr>
                      <td>
                          <input type="radio" id="on" name="catch_alerts" value="true" defaultChecked={!programObject.catch_alerts} />
                          <label style={{marginRight: 15, marginLeft: 5}} htmlFor="on" className="form-label">On</label>
                      </td>
                      <td>
                          <input type="radio" id="off" name="catch_alerts" value="false" defaultChecked={programObject.catch_alerts} />
                          <label style={{marginLeft: 5}} htmlFor="off" className="form-label">Off</label>
                      </td>
                  </tr></div>
                  
                  <div className="mb-3">
                      <label htmlFor="units-radio" className="form-label">Recatch Alert Emails*</label>
                      <tr>
                      <td>
                          <input type="radio" id="on" name="recatch_alerts" value="true" defaultChecked={!programObject.recatch_alerts} />
                          <label style={{marginRight: 15, marginLeft: 5}} htmlFor="on" className="form-label">On</label>
                      </td>
                      <td>
                          <input type="radio" id="off" name="recatch_alerts" value="false" defaultChecked={programObject.recatch_alerts} />
                          <label style={{marginLeft: 5}} htmlFor="off" className="form-label">Off</label>
                      </td>
                  </tr></div>
                  
                  <div className="form-group mb-3">
                      <label htmlFor="email-field" className="form-label">Notifications Address*</label>
                      <input type="email" id="email-field" className="form-control" placeholder="alerts@fish-trak.com" name="email_address" defaultValue={programObject.email_address}/>
                  </div>
                  
                  <div className="mb-3">
                      <label htmlFor="units-radio" className="form-label">Instructions Email*</label>
                      <tr>
                      <td>
                          <input type="radio" id="on" name="instructions" value="true" defaultChecked={!programObject.instructions} />
                          <label style={{marginRight: 15, marginLeft: 5}} htmlFor="on" className="form-label">On</label>
                      </td>
                      <td>
                          <input type="radio" id="off" name="instructions" value="false" defaultChecked={programObject.instructions} />
                          <label style={{marginLeft: 5}} htmlFor="off" className="form-label">Off</label>
                      </td>
                  </tr></div>
                  
                  <div className="mb-3">
                      <label htmlFor="units-radio" className="form-label">Questionnaire Email*</label>
                      <tr>
                      <td>
                          <input type="radio" id="on" name="questionnaire" value="true" defaultChecked={!programObject.questionnaire} />
                          <label style={{marginRight: 15, marginLeft: 5}} htmlFor="on" className="form-label">On</label>
                      </td>
                      <td>
                          <input type="radio" id="off" name="questionnaire" value="false" defaultChecked={programObject.questionnaire} />
                          <label style={{marginLeft: 5}} htmlFor="off" className="form-label">Off</label>
                      </td>
                  </tr></div>

                  <div className="text-left mt-4">
                    <Button type="submit" color="success" className="btn btn-success" id="add-btn">
                      Update Settings
                    </Button>
                  </div>
                </Form>
                }
                
                <ThreeCircles
                visible={isUpdating}
                height="100"
                width="100"
                color="#4192C3"
                ariaLabel="three-circles-loading"
                wrapperStyle={{style:"margin: 0px auto"}}
                wrapperClass="justify-content-center"
                />
                
              </CardBody>
                            
              
            </Card>
          </Container>
        </div>
    </React.Fragment>
  );
};

export default withRouter(ProgramSettings);
