import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid ={true}>
          <Row>
            <Col sm={6}>Copyright © {new Date().getFullYear()} <a href="https://fish-trak.com" target="_blank">Fish-Trak</a>.</Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                Developed by <a href="https://ayslabs.co" target="_blank">AYS Labs</a>.
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>

  );
}

export default Footer;