import React, { useRef, useState, useEffect } from 'react'

import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader, Alert, Label, Input, FormFeedback, Form } from 'reactstrap';
import withRouter from "../../components/Common/withRouter";

import { Link, useParams, useNavigate } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner'

//Firebase functionality
import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp} from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, uploadBytes, getStorage, ref } from "firebase/storage";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

const UserProfile = () => {
  
  document.title = "User Profile | Fish-Trak - Partner's Dashboard";
  
  //Firebase functionality
  const storage = getStorage();
  const navigate = useNavigate();
  
  //Session data references
  const userObject = JSON.parse(localStorage.getItem("userData"));
  const programObject = JSON.parse(localStorage.getItem("programData"));
  const userRef = firebase.firestore().collection('users').doc(userObject.id);
  
  //Session booleans
  const [initialLoad, setInitialLoad] = useState(true);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [fetchedData, setFetchedData] = useState(false);
  
  //Action references
  const [isUploading, setIsUploading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatedImage, setUpdatedImage] = useState(null);
  
  const submitUpdateForm = async (event) => {
  
      event.preventDefault();
      
      setIsUpdating(true);
      
      if(updatedImage) {
          updateImage(event);
      }
      else {
          updateUser(event);
      }
  
  }
  
  const updateImage = async (event) => {
      
      setIsUploading(true);
      event.preventDefault();
                  
    const imageFileName = '/user-avatars/' + userObject.id;
    
    const storageRef = ref(storage, imageFileName);
  
    const uploadTask = uploadBytesResumable(storageRef, updatedImage);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("upload is" + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload paused");
            break;
          case "running":
            console.log("Upload running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
        setIsUpdating(false);
          alert('Could not update user avatar. Please try again.');
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
          updateUser(event, downloadedURL);
        });
      }
    );
  };
  
  const updateUser = async (event, downloadedURL) => {
              
    //Create object of program data
      const userData = {
        updated_at: serverTimestamp(),
      };
      
      //User information      
      if(event.target.first_name.value != userObject.first_name) {
          userData['first_name'] = event.target.first_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
      }  
      if((userObject.last_name) && (event.target.last_name.value != userObject.last_name)) {
          userData['last_name'] = event.target.last_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
      } 
      else if((!userObject.last_name) || (userObject.last_name == '') || (userObject.last_name == null)) {
          userData['last_name'] = event.target.last_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
      }
      //Contact details
      if(event.target.email_address.value != userObject.email_address) {
          userData['email_address'] = event.target.email_address.value.replace(/[^a-zA-Z0-9+-_~!#$%&'./=^|{}"]/g, '');
      }  
      if(event.target.mobile_number.value != userObject.mobile_number) {
          userData['mobile_number'] = event.target.mobile_number.value.replace(/[^0-9+]/g, '');
      }  
      //Location data
      if(event.target.home_port.value != userObject.home_port) {
          userData['home_port'] = event.target.home_port.value.replace(/[^a-zA-Z&()-+',./' ]/g, '');
      } 
      if((event.target.home_port_lat.value != userObject.home_port_geo.latitude) || (event.target.home_port_lng.value != userObject.home_port_geo.longitude)) {
          userData['location'] = new firebase.firestore.GeoPoint(parseFloat(event.target.home_port_lat.value.replace(/[^0-9.-]/g, '')), parseFloat(event.target.home_port_lng.value.replace(/[^0-9.-]/g, '')))
      }
      if(event.target.user_country.value != userObject.country_code) {
          userData['country_code'] = event.target.user_country.value.replace(/[^a-zA-Z]/g, '');
      }
   
      //Unit system
      if(event.target.units.value != userObject.metric_system) {
          userData['metric_system'] = JSON.parse(event.target.units.value);
      }
      
      //Notifications
      if(event.target.email_updates.value != userObject.email_updates) {
          userData['email_updates'] = event.target.email_updates.checked;
      }
      if(event.target.text_messages.value != userObject.text_messages) {
          userData['text_messages'] = event.target.text_messages.checked;
      }
      if(event.target.recatch_alerts.value != userObject.recatch_alerts) {
          userData['recatch_alerts'] = event.target.recatch_alerts.checked;
      }
      if(event.target.push_notifications.value != userObject.push_notifications) {
          userData['push_notifications'] = event.target.push_notifications.checked;
      }

      if(downloadedURL) {
          userData['image_url'] = downloadedURL;
      }
              
      //Create new catch in firestore
      userRef
          .update(userData)
          .then((userResponse) => {
            setFetchingDetails(true);
            getUserDetails(); 
          })
          .catch((error) => {
            setIsUpdating(false);
            alert('Could not update user entry. Please try again.');
          });  
  };
  
  //Get user details
  const getUserDetails = async () => {
    userRef
    .get()
    .then((userDoc) => {
      const userData = userDoc.data();
      userData.id = userDoc.id;
      localStorage.setItem("userData", JSON.stringify(userData));
      setFetchingDetails(false);
      setIsUploading(false);
      setIsUpdating(false);
      setFetchedData(true);
    })
    .catch((error) => {
      setFetchingDetails(false);
      alert('Could not get user details. Please try again.');
    });  
  };

  return (
    <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg="12">

                <Card>
                
                  <CardHeader>
                      <h4 className="card-title mb-0">User Details</h4>
                  </CardHeader>
                  <CardBody>
                  
                    <ThreeCircles
                    visible={fetchingDetails}
                    height="100"
                    width="100"
                    color="#4192C3"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{style:"margin: 0px auto"}}
                    wrapperClass="justify-content-center"
                    />
                  
                    {!fetchingDetails && userObject &&
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={userObject.image_url}
                          alt={userObject.email_address}
                          className="avatar-xl rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                          <h5>{userObject.first_name} {userObject.last_name}</h5>
                          <p className="mb-1">{userObject.email_address}</p>
                          <p className="mb-0">{userObject.mobile_number}</p>
                          <p className="mb-0">{userObject.home_port}</p>
                        </div>
                      </div>
                    </div>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>


            <Card>
              
              <CardHeader>
                  <h4 className="card-title mb-0">Update Details</h4>
              </CardHeader>
            
              <CardBody>
                {!isUpdating && userObject &&
                <Form className="form-horizontal" onSubmit={submitUpdateForm}>
                
                  <div className="form-group mb-3"><tr>
                      <td>
                      <label htmlFor="firstname-field" className="form-label">First Name*</label>
                      <input type="text" id="firstname-field" name="first_name" className="form-control" placeholder="Richard" required defaultValue={userObject.first_name}/>
                      </td>
                      <td>
                      <label htmlFor="lastname-field" className="form-label">Last Name*</label>
                      <input type="text" id="lastname-field" className="form-control" name="last_name" placeholder="Ryan" required defaultValue={userObject.last_name}/>
                      </td>
                  </tr></div>
                  <div className="form-group mb-3">
                      <label htmlFor="email-field" className="form-label">Email Address*</label>
                      <input type="email" id="email-field" name="email_address" className="form-control" placeholder="rich@fish-trak.com" required defaultValue={userObject.email_address}/>
                  </div>
                  <div className="form-group mb-3">
                      <label htmlFor="mobile-field" className="form-label">Mobile Number*</label>
                      <input type="tel" id="mobile-field" className="form-control" placeholder="+14151234567" name="mobile_number" required defaultValue={userObject.mobile_number}/>
                  </div>
                  <div className="form-group mb-3">
                      <label htmlFor="image-field" className="form-label">User Avatar*</label>
                      <tr>
                      <td><img className="avatar-sm rounded-circle" alt={userObject.name} src={userObject.image_url}/></td>
                      <td><input type="file" name="image_link" id="image-field" className="form-control" onChange={(e) => { setUpdatedImage(e.target.files[0]) }} style={{marginLeft: 20, width: "100%"}}/></td>
                      </tr>
                  </div>
                  <div className="form-group mb-3">
                      <label htmlFor="homeport-field" className="form-label">Home Port Location*</label>
                      <input type="text" id="homeport-field" className="form-control" name="home_port" placeholder="Toms River" required defaultValue={userObject.home_port}/>
                  </div>
                  <div className="form-group mb-3"><tr>
                      <td>
                      <label htmlFor="latitude-field" className="form-label">Home Port Latitude*</label>
                      <input type="number" id="latitude-field" className="form-control" name="home_port_lat" placeholder="Enter Latitude*" required defaultValue={userObject.home_port_geo.latitude} />
                      </td>
                      <td>
                      <label htmlFor="longitude-field" className="form-label">Home Port Longitude*</label>
                      <input type="number" id="longitude-field" className="form-control" name="home_port_lng" placeholder="Enter Longitude*" required defaultValue={userObject.home_port_geo.longitude} />
                      </td>
                  </tr>
                  </div>
                  <div className="form-group mb-3">
                      <label htmlFor="country-field" className="form-label">User Country*</label>
                      <select className="form-control" data-trigger id="country-field" required name="user_country" defaultValue={userObject.country_code}>
                          <option value="">Select User Country</option>
                          <option value="USA">United States</option>
                          <option value="CAN">Canada</option>
                      </select>
                  </div>
                  
                  <div className="form-group mb-3">
                      <label htmlFor="units-radio" className="form-label">Units System*</label>
                      <tr>
                      <td>
                          <input type="radio" id="metric" name="units" value="true" defaultChecked={userObject.metric_system} />
                          <label style={{marginRight: 15, marginLeft: 5}} htmlFor="metric" className="form-label">Metric</label>
                      </td>
                      <td>
                          <input type="radio" id="imperial" name="units" value="false" defaultChecked={!userObject.metric_system} />
                          <label style={{marginLeft: 5}} htmlFor="imperial" className="form-label">Imperial</label>
                      </td>
                  </tr></div>
                  
                  <div className="form-group mb-3">
                      <label htmlFor="notifications-checkbox" className="form-label">Notifications*</label>
                      <tr>
                          <td>
                              <input type="checkbox" id="emails" name="email_updates" value="true" defaultChecked={userObject.email_updates}/>
                              <label style={{marginRight: 15, marginLeft: 5}} htmlFor="emails"> Email Updates</label>
                          </td>
                          <td>
                              <input type="checkbox" id="texts" name="text_messages" value="true" defaultChecked={userObject.text_messages}/>
                              <label style={{marginLeft: 5}} htmlFor="texts"> Text Messages</label>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <input type="checkbox" id="alerts" name="recatch_alerts" value="true" defaultChecked={userObject.recatch_alerts}/>
                              <label style={{marginRight: 15, marginLeft: 5}} htmlFor="alerts"> Recatch Alerts</label>
                          </td>
                          <td>
                              <input type="checkbox" id="pushes" name="push_notifications" value="true" defaultChecked={userObject.notifications}/>
                              <label style={{marginLeft: 5}} htmlFor="pushes"> Push Notifications</label>
                          </td>
                      </tr>
                  </div>
                
                  <div className="text-left mt-4">
                    <Button type="submit" color="success">
                      Update Details
                    </Button>
                  </div>
                </Form>
                }
                
                <ThreeCircles
                visible={isUpdating}
                height="100"
                width="100"
                color="#4192C3"
                ariaLabel="three-circles-loading"
                wrapperStyle={{style:"margin: 0px auto"}}
                wrapperClass="justify-content-center"
                />
                
              </CardBody>
            </Card>
          </Container>
        </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
