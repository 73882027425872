import React from "react";
import Routes from "./Routes/index";

// Import Scss
import './assets/scss/theme.scss';

// Fake Backend 
import fakeBackend from "./helpers/AuthType/fakeBackend";

// Firebase
// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper"

const firebaseConfig = {
  apiKey: "AIzaSyCUKa1iHfZAdAjR8bZJhjTzHbnYWK2wRkI",
  authDomain: "fish-trak-app.firebaseapp.com",
  databaseURL: "https://fish-trak-app-default-rtdb.firebaseio.com",
  projectId: "fish-trak-app",
  storageBucket: "fish-trak-app.appspot.com",
  messagingSenderId: "109814780966",
  appId: "1:109814780966:web:f1eeaeca7b17c15fa7c2e7",
  measurementId: "G-RLZ0ZV2RGK"
};

// init firebase backend
initFirebaseBackend(firebaseConfig)

// Activating fake backend
// fakeBackend();

function App() {
  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;
