
import React, { useState, useEffect } from "react";

import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader, Alert, Label, Input, FormFeedback, Form } from 'reactstrap';

import { Link, useParams, useNavigate } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner'

//Import Firebase Functionality
import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp} from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, uploadBytes, getStorage, ref } from "firebase/storage";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

const ProgramProfile = () => {
  
  document.title = "Program Profile | Fish-Trak - Partner's Dashboard";
  
  //Firebase functionality
  const storage = getStorage();
  const navigate = useNavigate();
  
  //Session booleans
  const [initialLoad, setInitialLoad] = useState(true);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [fetchedData, setFetchedData] = useState(false);
    
  //Session data references
  const userObject = JSON.parse(localStorage.getItem("userData"));
  const programObject = JSON.parse(localStorage.getItem("programData"));
  const programRef = firebase.firestore().collection('programs').doc(programObject.id);
  
  //Action references
  const [isUploading, setIsUploading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatedImage, setUpdatedImage] = useState(null);

  const submitUpdateForm = async (event) => {
  
      event.preventDefault();
      
      setIsUpdating(true);
      
      if(updatedImage) {
          updateImage(event);
      }
      else {
          updateProgram(event);
      }
  
  }

  const updateImage = async (event) => {
      
      event.preventDefault();
    
    setIsUploading(true);
    
    const imageFileName = '/program-logos/' + event.target.program_name.value.replace(/[^0-9a-zA-Z-]/g, '-');
         
    const storageRef = ref(storage, imageFileName);
  
    const uploadTask = uploadBytesResumable(storageRef, updatedImage);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("upload is" + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload paused");
            break;
          case "running":
            console.log("Upload running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
        setIsUpdating(false);
          alert('Could not update program image. Please try again.');
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
          updateProgram(event, downloadedURL);
        });
      }
    );
  };
  
  const updateProgram = async (event, downloadedURL) => {
      
    //Create object of program data
      const programData = {
        updated_at: serverTimestamp(),
      };
      
      if(event.target.program_name.value != programObject.program_name) {
          programData['program_name'] = event.target.program_name.value.replace(/[^0-9a-zA-Z'-,.?& ]/g, '');
      }        
      if(event.target.tag_length.value != programObject.tag_length) {
          programData['tag_length'] = event.target.tag_length.value.replace(/[^0-9]/g, '');
      }
      if(event.target.program_region.value != programObject.program_region) {
          programData['program_region'] = event.target.program_region.value.replace(/[^a-zA-Z'-,.&+ ]/g, '');
      }
      if(event.target.program_country.value != programObject.country_code) {
          programData['country_code'] = event.target.program_country.value;
      }
      if(event.target.program_type.value != programObject.program_type) {
          programData['program_type'] = event.target.program_type.value;
      }
      if(event.target.program_website.value != programObject.website_url) {
          programData['website_url'] = event.target.program_website.value;
      }
      
      if(event.target.email_address.value != programObject.email_address) {
          programData['email_address'] = event.target.email_address.value;
      }
      
      if(downloadedURL) {
          programData['image_url'] = downloadedURL;
      }
              
      //Update program data
      programRef
          .update(programData)
          .then((programResponse) => {
            setFetchingDetails(true);
            getProgramDetails(); 
          })
          .catch((error) => {
            setIsUpdating(false);
            alert('Could not update program entry. Please try again.');
          });  
  };
  
  //Get user details
  const getProgramDetails = async () => {
    programRef
    .get()
    .then((programDoc) => {
      const programData = programDoc.data();
      programData.id = programDoc.id;   
      localStorage.setItem("programData", JSON.stringify(programData));         
      setFetchingDetails(false);
      setIsUploading(false);
      setIsUpdating(false);
      setFetchedData(true);
      
    })
    .catch((error) => {
      setFetchingDetails(false);
      alert('Could not get program details. Please try again.');
    });  
  };

  useEffect(() => {
  });

  return (
    <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg="12">

                <Card>
                
                  <CardHeader>
                      <h4 className="card-title mb-0">Program Profile</h4>
                  </CardHeader>
                  <CardBody>
                  
                    <ThreeCircles
                    visible={fetchingDetails}
                    height="100"
                    width="100"
                    color="#4192C3"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{style:"margin: 0px auto"}}
                    wrapperClass="justify-content-center"
                    />
                    
                    {!fetchingDetails && programObject &&
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={programObject.image_url}
                          alt={programObject.program_name}
                          className="avatar-xl rounded img-thumbnail"
                        />
                      </div>
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                          <h5>{programObject.program_name}</h5>
                          <p className="mb-1">Program Location: {programObject.program_region}, {programObject.country_code}</p>
                          <p className="mb-0">Program Type: {programObject.program_type}</p>
                          <p className="mb-0">Program Website: <a href={programObject.website_url} className="fw-medium link-primary">{programObject.website_url}</a></p>
                          <p className="mb-0">Tag Length: <span className="badge badge-soft-success text-uppercase">{programObject.tag_length}</span></p>
                          {programObject.email_address && <p className="mb-0">Alerts Email: {programObject.email_address}</p>}
                        </div>
                      </div>
                    </div>
                  }
                  </CardBody>
                </Card>
              </Col>
            </Row>


            <Card>
              
              <CardHeader>
                  <h4 className="card-title mb-0">Update Details</h4>
              </CardHeader>
 
              <CardBody>
                {!isUpdating && programObject &&
                <Form className="form-horizontal" onSubmit={submitUpdateForm}>
                
                  <div className="form-group mb-3">
                    <label htmlFor="name-field" className="form-label">Program Name*</label>
                    <input type="text" id="name-field" className="form-control" placeholder="Fish-Trak Tagging Program" required name="program_name" defaultValue={programObject.program_name}/>
                  </div>
                  
                  <div className="form-group mb-3">
                    <label htmlFor="length-field" className="form-label">Tag Length*</label>
                    <input type="number" id="length-field" maxLength="2" className="form-control" placeholder="Max: 15" required name="tag_length" defaultValue={programObject.tag_length}/>
                  </div>
                  
                  <div className="form-group mb-3">
                      <label htmlFor="image-field" className="form-label">Image File*</label>
                      <tr>
                      <td><img className="avatar-sm rounded" alt={programObject.program_name} src={programObject.image_url}/></td>
                      <td><input type="file" name="image_link" id="image-field" className="form-control" onChange={(e) => { setUpdatedImage(e.target.files[0]) }} style={{marginLeft: 20, width: "100%"}}/></td>
                      </tr>
                  </div>
                  
                  <div className="form-group mb-3">
                    <label htmlFor="region-field" className="form-label">Program Region*</label>
                    <input id="region-field" type="text" className="form-control" placeholder="Your Program Name" required name="program_region" defaultValue={programObject.program_region}/>
                  </div>
                  
                  <div className="form-group mb-3">
                      <label htmlFor="country-field" className="form-label">Program Country*</label>
                      <select className="form-control" data-trigger id="country-field" required name="program_country" defaultValue={programObject.country_code}>
                          <option value="">Select Program Country</option>
                          <option value="USA">United States</option>
                          <option value="CAN">Canada</option>
                          <option value="GBR">United Kingdom</option>
                      </select>
                  </div>                        
                  
                  <div className="form-group mb-3">
                      <label htmlFor="type-field" className="form-label">Program Type*</label>
                      <select className="form-control" data-trigger id="type-field" required name="program_type" defaultValue={programObject.program_type}>
                          <option value="">Select Program Type</option>
                          <option value="Private">Private</option>
                          <option value="Public">Public</option>
                          <option value="University">University</option>
                          <option value="Government">Government</option>
                          <option value="State">State</option>
                          <option value="Federal">Federal</option>
                      </select>
                  </div> 
                  
                  <div className="form-group mb-3">
                      <label htmlFor="website-field" className="form-label">Program Website*</label>
                      <input type="text" id="website-field" className="form-control" placeholder="https://fish-trak.com" required name="program_website" defaultValue={programObject.website_url}/>
                  </div>   
                  
                  {/* <div className="form-group mb-3">
                      <label htmlFor="email-field" className="form-label">Notification Email</label>
                      <input type="email" id="email-field" className="form-control" placeholder="alerts@fish-trak.com" name="email_address" defaultValue={programObject.email_address}/>
                  </div>    */}

                  <div className="text-left mt-4">
                    <Button type="submit" color="success" className="btn btn-success" id="add-btn">
                      Update Details
                    </Button>
                  </div>
                </Form>
                }
                
                <ThreeCircles
                visible={isUpdating}
                height="100"
                width="100"
                color="#4192C3"
                ariaLabel="three-circles-loading"
                wrapperStyle={{style:"margin: 0px auto"}}
                wrapperClass="justify-content-center"
                />
                
              </CardBody>
                            
              
            </Card>
          </Container>
        </div>
    </React.Fragment>
  );
};

export default withRouter(ProgramProfile);
