import React, { useState } from 'react';

// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
    Form,
    Card,
    CardBody,
    Col,
    Row,
    Container,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    CardTitle,
    CardHeader
} from "reactstrap";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
const Instructions = () => {
    document.title = "Email Compose  | Upzet - React Admin & Dashboard Template";

    const [folderbtn, setfolderbtn] = useState(false);
    const [tagbtn, settagbtn] = useState(false);
    const [menubtn, setmenubtn] = useState(false);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <CardTitle>Notifications</CardTitle>
                    <Row className="mb-4">
                        <Col xl={12}>
                            <Card className="mb-0">
                                <CardHeader>
                                    <h4 className="card-title mb-0">Instructions Email</h4>
                                </CardHeader>
                                
                                <CardBody>
                                    <div className="mb-0">
                                        <div className="mb-3">
                                            <input type="email" className="form-control" placeholder="Sender" />
                                        </div>

                                        <div className="mb-3">
                                            <input type="text" className="form-control" placeholder="Subject" />
                                        </div>
                                        <div id="email-editor" style={{ minHeight: "360px" }}>
                                            <Form method="post" >
                                                <Editor className="mb-3 ql-size-large"

                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    placeholder="Enter Catch Instructions..."
                                                />
                                            </Form>
                                        </div>
                                        <div className="btn-toolbar">
                                            <div className="">
                                                 <button type="button" className="btn btn-danger waves-effect waves-light me-1"><span>Clear</span> <i className="far fa-trash-alt"></i></button>
                                                <button className="btn btn-primary waves-effect waves-light"> <span>Save</span> <i className="fa fa-save ms-2"></i> </button>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Instructions;