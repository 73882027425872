import React, { useRef, useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, CardHeader } from 'reactstrap';
import { ThreeCircles } from 'react-loader-spinner'
import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";

const LatestCatches = () => {
    
    const [latestCatches, setLatestCatches] = useState([]);
    const [fetchingCatches, setFetchingCatches] = useState(true);
    
    //Session data references
    const userData = JSON.parse(localStorage.getItem("userData"));
    const programData = JSON.parse(localStorage.getItem("programData"));
    const programRef = firebase.firestore().collection('programs').doc(programData.id);
    
    const fetchLatestCatches = async () => {
          
     firebase
     .firestore()
     .collection('catches')
     .where('tag_program', '==', programRef)
     .orderBy('created_at', 'desc')
     .limit(5)
     .onSnapshot(
          querySnapshot => {
              const newLatestCatches = []
              querySnapshot.forEach(doc => {
                  const catchItem = doc.data();
                  catchItem.id = doc.id;
                  catchItem.link = 'catch/details/' + catchItem.id;  
                  catchItem.tag_link = 'tag/details/' + catchItem.tag.id; 
                  catchItem.user_link = 'user/details/' + catchItem.user.id; 
                  catchItem.date = catchItem && catchItem.catch_date && catchItem.catch_date.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  
                  newLatestCatches.push(catchItem);
              });
              setLatestCatches(newLatestCatches);
              setFetchingCatches(false);
          },
          error => {
              console.log(error);
          }
      )
    };
    
    //Update on load of data
    useEffect(() => {
        fetchLatestCatches();
    }, []);
    
    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle>Latest Catches</CardTitle>
                        </CardHeader>
                    
                        <CardBody>
                        
                            <ThreeCircles
                            visible={fetchingCatches}
                            height="100"
                            width="100"
                            color="#4192C3"
                            ariaLabel="three-circles-loading"
                            wrapperStyle={{style:"margin: 0px auto"}}
                            wrapperClass="justify-content-center"
                            />
                        
                            {!fetchingCatches &&
                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">

                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Catch Information</th>
                                            <th scope="col">Catch Date</th>
                                            <th scope="col">Angler Name</th>
                                            <th scope="col">Catch Location</th>
                                            <th scope="col">Tag Number</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {latestCatches.map((catchItem, key) => (<tr key={key}>
                                            <td>
                                                {catchItem.image_url ? <img src={catchItem.image_url} alt={catchItem.name} className="avatar-xs rounded-circle" /> : <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                        {catchItem.name.charAt(0)}
                                                    </span>
                                                </div>}
                                            </td>
                                            <td>
                                                <p className="mb-1 font-size-12">{catchItem.info}</p>
                                                <h5 className="font-size-15 mb-0">{catchItem.name}</h5>
                                            </td>
                                            <td>{catchItem.date}</td>
                                            <td><a href={catchItem.user_link} className="fw-medium link-primary">{catchItem.user_name}</a></td>
                                            <td>{catchItem.location_string}</td>
                                            <td><a href={catchItem.tag_link} className="fw-medium link-primary">{catchItem.tag_number}</a></td>
                                            <td>
                                                {catchItem && catchItem.recatch &&
                                                    <span className="badge badge-soft-warning text-uppercase">Recatch</span>
                                                }
                                                {catchItem && !catchItem.recatch &&
                                                    <span className="badge badge-soft-success text-uppercase">Catch</span>
                                                }
                                            </td>
                                            <td>
                                                <a href={catchItem.link} className="btn btn-outline-success btn-sm me-1"><i className="mdi mdi-eye-outline"></i></a>
                                            </td>
                                        </tr>))}
                                    </tbody>
                                </table>
                            </div>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default LatestCatches;