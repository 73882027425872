import React, { useRef, useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, CardHeader } from 'reactstrap';

import { LatestTransationData } from '../../CommonData/Data/index';
import { ThreeCircles } from 'react-loader-spinner'
import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";

const LatestTags = () => {
    
    const [fetchingTags, setFetchingTags] = useState(true);
    const [latestTags, setLatestTags] = useState([]);
    
    //Session data references
    const userData = JSON.parse(localStorage.getItem("userData"));
    const programData = JSON.parse(localStorage.getItem("programData"));
    
    const fetchLatestTags = async () => {
        
    const programRef = firebase.firestore().collection('programs').doc(programData.id); 
          
     firebase
     .firestore()
     .collection('tags')
     .where('tag_program', '==', programRef)
     .orderBy('created_at', 'desc')
     .limit(5)
     .onSnapshot(
          querySnapshot => {
              const newLatestTags = []
              querySnapshot.forEach(doc => {
                  const tagItem = doc.data();
                  tagItem.id = doc.id;
                  tagItem.link = '../tag/details/' + tagItem.id;  
                  tagItem.created = tagItem && tagItem.created_at && tagItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  
                  tagItem.updated = tagItem && tagItem.updated_at && tagItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  
                  newLatestTags.push(tagItem);
              });
              setLatestTags(newLatestTags);
              setFetchingTags(false);
          },
          error => {
              console.log(error);
          }
      )
    };
    
    //Update on load of data
    useEffect(() => {
        fetchLatestTags();
    }, []);
    
    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle>Latest Fish-Tags</CardTitle>
                        </CardHeader>

                        <CardBody>
                        
                            <ThreeCircles
                            visible={fetchingTags}
                            height="100"
                            width="100"
                            color="#4192C3"
                            ariaLabel="three-circles-loading"
                            wrapperStyle={{style:"margin: 0px auto"}}
                            wrapperClass="justify-content-center"
                            />
                            
                            {!fetchingTags &&
                        
                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">

                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Tag Information</th>
                                            <th scope="col">Created</th>
                                            <th scope="col">Updated</th>
                                            <th scope="col">Catches</th>
                                            <th scope="col">Tag Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {latestTags.map((tag, key) => (<tr key={key}>
                                            <td>
                                                <img src={tag.program_image} alt="user" className="avatar-xs rounded-circle" />
                                            </td>
                                            <td>
                                                <p className="mb-1 font-size-12">{tag.program_name}</p>
                                                <h5 className="font-size-15 mb-0">{tag.tag_number}</h5>
                                            </td>
                                            <td>{tag.created}</td>
                                            <td>{tag.updated}</td>
                                            <td>{tag.catch_count}</td>
                                            <td>
                                                {tag.used &&<i className={"mdi mdi-checkbox-blank-circle me-1 text-success"}> Used</i>}
                                                {!tag.used &&<i className={"mdi mdi-checkbox-blank-circle me-1 text-danger"}> Un-Used</i>}
                                            </td>
                                            <td>
                                                <a href={tag.link} className="btn btn-outline-success btn-sm me-1"><i className="mdi mdi-eye-outline"></i></a>
                                            </td>
                                        </tr>))}
                                    </tbody>
                                </table>
                            </div>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default LatestTags;