import React from "react";
import UsePanel from "./UserPanel";
import OrderStatus from "./OrderStatus";
import Notifications from "./Notifications";
import SocialSource from "./SocialSource";
import OverView from "./OverView";
import RevenueByLocation from "./RevenueByLocation";
import LatestTransation from "./LatestTransation";

import LatestTags from "./LatestTags";
import LatestCatches from "./LatestCatches";
import RecentCatches from "./RecentCatches";

import { Container, Col, Row, Card, CardBody, CardTitle } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Dashboard = () => {
  document.title = "Overview | Fish-Trak Partner's Dashboard";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <CardTitle>My Overview</CardTitle>
          {/* User Panel Charts */}
          <UsePanel />

          {/* <Row> */}
            {/* Overview Chart */}
            {/* <OverView /> */}
            {/* Social Source Chart */}
            {/* <SocialSource /> */}
          {/* </Row> */}

          {/* <Row> */}
            {/* Order Stats */}
            {/* <OrderStatus /> */}
            {/* Notifications */}
            {/* <Notifications /> */}
            {/* Revenue by Location Vector Map */}
            {/* <RevenueByLocation /> */}
          {/* </Row> */}

          {/* Latest Transaction Table */}
          <LatestCatches />
          <LatestTags />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
