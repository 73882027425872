import PropTypes from "prop-types";
import React from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { createSelector } from 'reselect';

import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/bg.png";
import logo from "../../assets/images/logo-sm.png";

import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';

const ForgetPasswordPage = props => {
  const dispatch = useDispatch();
  document.title = "Forgot Password | Fish-Trak Partner's Dashboard";

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      dispatch(userForgetPassword(values, props.router.navigate));
    }
  });

  const forgetpass = createSelector(
    (state ) => state.forgetPassword,
    (layout) => ({
        forgetSuccessMsg: layout.forgetSuccessMsg,
    })
  );
// Inside your component
const { forgetSuccessMsg, forgetError } = useSelector(forgetpass);

  return (
    <React.Fragment>
    
    
    <div className="bg-pattern" style={{height:"100vh"}}>
        <div className="bg-overlay"></div>
        <div className="account-pages pt-5">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={6} md={8} xl={4}>
                        <Card className='mt-5'>
                            <CardBody className="p-4">
                                <div className="">
                                    <div className="text-center">
                                         <Link to="/" className="">
                                            <img src={logodark} alt="" height="100" className="auth-logo logo-dark mx-auto" />
                                            <img src={logolight} alt="" height="100" className="auth-logo logo-light mx-auto" />
                                         </Link>
                                    </div>
                                    <h4 className="font-size-18 text-muted mt-2 text-center">Forgot Your Password?</h4>
                                    <p className="mb-5 text-center">Reset your Partner's account password.</p>
                                    <Form
                                      className="form-horizontal"
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                      }}
                                    >
                                        <Row>
                                            <Col md={12}>
                                                {forgetError && forgetError ? (
                                                  <Alert color="danger" style={{ marginTop: "13px" }}>
                                                    {forgetError}
                                                  </Alert>
                                                ) : null}
                                                {forgetSuccessMsg ? (
                                                  <Alert color="success" style={{ marginTop: "13px" }}>
                                                    {forgetSuccessMsg}
                                                  </Alert>
                                                ) : null}
    
                                                <div className="mb-3">
                                                  <Label className="form-label">Email</Label>
                                                  <Input
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.email || ""}
                                                    invalid={
                                                      validation.touched.email && validation.errors.email ? true : false
                                                    }
                                                  />
                                                  {validation.touched.email && validation.errors.email ? (
                                                    <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                  ) : null}
                                                </div>
                                                
                                                <div className="d-grid mt-4">
                                                    <button
                                                      className="btn btn-primary w-md "
                                                      type="submit"
                                                    >
                                                      Reset
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                        <div className="mt-5 text-center">
                            <p className="text-white-50">
                                Copyright © {new Date().getFullYear()} <a href="https://fish-trak.com" target="_blank">Fish-Trak</a>. All Rights Reserved.
                              </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
